<template>
  <div>
    <section class="white">
      <v-container>
        <v-row class="pb-5 pt-5">
          <v-col
            cols="10"
            offset="1"
            style="font-family:'Kanit'!important"
          >
            <v-col
              cols="12"
              max-width="500"
              outlined
              tile
              class="mb-5 centered text-justify pure-border"
            >
              <h2
                :class="[$vuetify.breakpoint.mdAndUp ? 'display-1' : 'headline']"
                class="mb-2"
                style="font-family:'Kanit'!important"
              >
                KIM JESTEŚMY
              </h2>
            </v-col>
            <v-container class="d-md-inline-flex">
              <v-col
                sm="12"
              >
                <v-card
                  outlined
                  tile
                  class="mb-5 centered text-justify"
                >
                  <v-card-text>
                    <p>
                      Działamy na terenie byłej Fabryki "WAGON" S.A. w Ostrowie Wielkopolskim - dziś Polski Tabor Szynowy sp. z o.o. Jesteśmy dostawcą usług oraz energii elektrycznej dla przedsiębiorstw w naszym otoczeniu. Do chwili obecnej z naszych usług korzystała głównie firma PTS sp. z o.o. oraz Kuźnia Ostrów Wielkopolski. Aktualnie rozszerzamy swoją działalność na teren miasta Ostrowa Wielkopolskiego oraz kamiennogórskiej strefy ekonomicznej i okolic. Nasza oferta kierowana jest zarówno do osób fizycznych jak i do osób prawnych. Zapraszamy do korzystania z naszych usług.
                    </p>
                    <span class="text-xs-right"><i>Jesteśmy solidnym partnerem dla wielu...<br>
                      ... a możemy być i dla Państwa.</i>
                    </span>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-container>
          </v-col>
        </v-row>
        <v-row
          class="pb-5 pt-5 background-light-grey"
        >
          <v-col
            cols="10"
            offset="1"
            style="font-family:'Kanit'!important"
          >
            <v-col
              cols="12"
              max-width="500"
              outlined
              tile
              class="mb-5 centered text-justify pure-border"
            >
              <h2
                :class="[$vuetify.breakpoint.mdAndUp ? 'display-1' : 'headline']"
                class="mb-2"
                style="font-family:'Kanit'!important"
              >
                MISJA
              </h2>
              <p
                class="subtitle-1 text-justify mb-1"
                style="font-family:'Kanit'!important"
              >
                ENERGETYKA WAGON SP. Z O.O.
              </p>
            </v-col>
            <v-container>
              <v-col cols="12">
                <v-card
                  outlined
                  tile
                  class="mb-5 centered text-justify"
                >
                  <v-card-text>
                    <h2>„DOBRA ROBOTA”</h2>
                    <p>
                      czyli spełnienie oczekiwań naszych klientów w zakresie dobrej jakości usług i odbiorców w zakresie dostaw energii elektrycznej. Truizm?! Niekoniecznie! Z wielu powodów; dziś coraz trudniej o zwyczajną, rzetelną, terminową pracę. Realizując misję, Zarząd Spółki Energetyka WAGON zobowiązuje się do ciągłego doskonalenia działalności, a w szczególności do:
                    </p>
                    <ul>
                      <li>doskonalenia usług w oparciu o aktualne i przyszłe potrzeby klientów</li>
                      <li>motywowania pracowników sprzyjającego ciągłej poprawie jakości pracy</li>
                      <li>kształcenie pracowników, podnoszenie ich świadomości, kwalifikacji i umiejętności</li>
                      <li>utrwalania w świadomości personelu przekonania, iż za jakość pracy oraz bezpieczeństwo człowieka i środowiska odpowiedzialni są wszyscy pracownicy Spółki</li>
                      <li>utrwalania świadomości, że zaufanie do firmy jest wynikiem rzetelności i odpowiedzialności wobec otoczenia zapobiegania wypadkom i chorobom zawodowym</li>
                      <li>dbałość o środowisko w trosce o zdrowie przyszłych pokoleń</li>
                    </ul>
                    <br>
                    <p>
                      Jesteśmy firmą, z którą warto współpracować i dla której warto pracować. Stawiamy na:
                    </p>
                    <ul>
                      <li>odpowiedzialność - każdy z nas wie, że wszystko od niego zależy</li>
                      <li>innowacyjność – zabieganie o nowe pomysły i ich realizacja</li>
                      <li>uczciwość - uczciwe zachowanie wobec klienta i względem siebie</li>
                      <li>szacunek - wykorzystanie pełnych możliwości zespołu poprzez wkład każdej osoby</li>
                    </ul>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-container>
          </v-col>
        </v-row>
        <v-row class="pb-5 pt-5">
          <v-col
            cols="10"
            offset="1"
            style="font-family:'Kanit'!important"
          >
            <v-col
              cols="12"
              max-width="500"
              outlined
              tile
              class="mb-5 centered text-justify pure-border"
            >
              <h2
                :class="[$vuetify.breakpoint.mdAndUp ? 'display-1' : 'headline']"
                class="mb-2"
                style="font-family:'Kanit'!important"
              >
                AKTUALNOŚCI
              </h2>
              <p
                class="subtitle-1 text-justify mb-1"
                style="font-family:'Kanit'!important"
              >
                CAŁODOBOWY TELEFON ALARMOWY: <b>+48 512 929 091</b> LUB <b>+48 62 595 35 26</b>
              </p>
            </v-col>
            <v-container class="d-inline-flex">
              <v-col cols="12">
                <v-card
                  outlined
                  tile
                  class="mb-5 centered text-justify"
                >
                  <v-card-text>
                    <ol>
                      <li><b>3 Marzec 2023</b><br>
                        <a
                          href="http://documents.energetyka-wagon.pl/15-zmiana-taryfy-3-2023.pdf"
                          target="_blank"
                        >
                          Zarząd Energetyki WAGON Sp. z o.o. informuje o zmienionej taryfie opłat. Zmiana wchodzi w życie z dniem 15 marca 2023 roku.
                        </a>
                      </li>
                      <li><b>16 Styczeń 2023</b><br>
                        <a
                          href="http://documents.energetyka-wagon.pl/14-zmiana-taryfy-1-2023.pdf"
                          target="_blank"
                        >
                          Zarząd Energetyki WAGON Sp. z o.o. informuje o zmienionej taryfie opłat. Zmiana wchodzi w życie z dniem 1 lutego 2023 roku.
                        </a>
                      </li>
                      <li><b>18 Październik 2022</b><br>
                        <a
                          href="http://documents.energetyka-wagon.pl/13-zmiana-taryfy-10-2022.pdf"
                          target="_blank"
                        >
                          Zarząd Energetyki WAGON Sp. z o.o. informuje o zmienionej taryfie opłat. Zmiana wchodzi w życie z dniem 1 listopada 2022 roku.
                        </a>
                      </li>
                      <li><b>30 Wrzesień 2022</b><br>
                        <a
                          href="http://documents.energetyka-wagon.pl/12-cennik-9-2022.pdf"
                          target="_blank"
                        >
                          Zarząd Energetyki WAGON Sp. z o.o. informuje o wprowadzeniu nowego cennika sprzedaży energii elektrycznej. Zmiana wchodzi w życie z dniem 1 października 2022 roku.
                        </a>
                      </li>
                      <li><b>04 Maj 2022</b><br>
                        <a
                          href="http://documents.energetyka-wagon.pl/11-ure-osd-5-2022.pdf"
                          target="_blank"
                        >
                          Zarząd Energetyki WAGON Sp. z o.o. informuje o przedłużeniu decyzji o wyznaczeniu Przedsiębiorstwa operatorem systemu dystrybucyjnego elektroenergetycznego do dnia 31 grudnia 2040 roku.
                        </a>
                      </li>
                      <li><b>12 Kwiecień 2022</b><br>
                        <a
                          href="http://documents.energetyka-wagon.pl/10-dystrybucja-4-2022-2.pdf"
                          target="_blank"
                        >
                          Zarząd Energetyki WAGON Sp. z o.o. informuje o przedłużeniu koncesji na dystrybucję energii elektrycznej do dnia 31 grudnia 2040 roku.
                        </a>
                      </li>
                      <li><b>12 Kwiecień 2022</b><br>
                        <a
                          href="http://documents.energetyka-wagon.pl/9-obrot-4-2022.pdf"
                          target="_blank"
                        >
                          Zarząd Energetyki WAGON Sp. z o.o. informuje o przedłużeniu koncesji na obrót energią elektryczną do dnia 31 grudnia 2040 roku.
                        </a>
                      </li>
                      <li><b>14 Marca 2022</b><br>
                        <a
                          href="http://documents.energetyka-wagon.pl/8-zmiana-taryfy-3-2022.pdf"
                          target="_blank"
                        >
                          Zarząd Energetyki WAGON Sp. z o.o. informuje o zmienionej taryfie opłat. Zmiana wchodzi w życie z dniem 1 kwietnia 2022 roku.
                        </a>
                      </li>
                      <li><b>15 Grudzień 2021</b><br>
                        <a
                          href="http://documents.energetyka-wagon.pl/7-cennik-12-2021.pdf"
                          target="_blank"
                        >
                          Zarząd Energetyki WAGON Sp. z o.o. informuje o wprowadzeniu nowego cennika sprzedaży energii elektrycznej. Zmiana wchodzi w życie z dniem 1 stycznia 2022 roku.
                        </a>
                      </li>
                      <li><b>26 Kwiecień 2021</b><br>
                        <a
                          href="http://documents.energetyka-wagon.pl/6-energetyka-wagon-taryfa-4-2021.pdf"
                          target="_blank"
                        >
                        Zarząd Energetyki WAGON Sp. z o.o. informuje o wprowadzeniu nowej taryfy opłat. Zmiana wchodzi w życie z dniem 1 czerwca 2021 roku.
                        </a>
                      </li>
                      <li><b>30 Czerwca 2020</b><br>
                        <a
                          href="http://documents.energetyka-wagon.pl/5-cennik-6-2020.pdf"
                          target="_blank"
                        >
                          Zarząd Energetyki WAGON Sp. z o.o. informuje o wprowadzeniu nowego cennika sprzedaży energii elektrycznej. Zmiana wchodzi w życie z dniem 1 lipca 2020 roku.
                        </a>
                      </li>
                      <li><b>10 Marzec 2020</b><br>
                        <a
                          href="http://documents.energetyka-wagon.pl/4-postanowienie-sprostowanie-3-2020.pdf"
                          target="_blank"
                        >
                          Zarząd Energetyki WAGON Sp. z o.o. informuje o wprowadzeniu sprostowania do zmiany w taryfie opłat.
                        </a>
                      </li>
                      <li><b>26 Luty 2020</b><br>
                        <a
                          href="http://documents.energetyka-wagon.pl/_.pdf"
                          target="_blank"
                        >
                          Zarząd Energetyki WAGON Sp. z o.o. informuje o zmienionej taryfie opłat. Zmiana wchodzi w życie z dniem 1 kwietnia 2020 roku.
                        </a>
                      </li>
                      <li><b>08 Styczeń 2020</b><br>
                        <a
                          href="http://documents.energetyka-wagon.pl/2-iriesd-1-2020.pdf"
                          target="_blank"
                        >
                          Zarząd Energetyki WAGON Sp. z o.o. informuje o wprowadzeniu nowej Instrukcji Ruchu i Eksploatacji Sieci Dystrybucyjnej. Zmiana wchodzi w życie z dniem 8 stycznia 2020 roku.
                        </a>
                      </li>
                      <li><b>21 Listopad 2019</b><br>
                        <a
                          href="http://documents.energetyka-wagon.pl/1-taryfa-energetyka-wagon-11-2019.pdf"
                          target="_blank"
                        >
                          Zarząd Energetyki WAGON Sp. z o.o. informuje o wprowadzeniu nowej taryfy opłat. Zmiana wchodzi w życie z dniem 1 stycznia 2020 roku.
                        </a>
                      </li>
                    </ol>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<style scoped>
  .pure-border {
    border-left: 3px solid black;
  }
  .background-light-grey {
    background-color: #f1f1f1;
  }
</style>

<script>
  export default {
  }
</script>
